import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import MerchantSignUpButton from '../components/merchantSignUpButton'

export default function Partners () {
  return (
    <Layout legacy>
      <section>
        <div className='row justify-center'>
          <div className='col text-center w75'>
            <div className="inner">
              <h1>Your Real-time, Same-Day, Local Delivery Partner.</h1>
              <p>Accelerate your local pickup and delivery speed and capacity, at scale. Trexity is made to add same-day delivery to your business: so you can easily respond to changing consumer demand.</p>

              <div className="partner-logos">
                <a href="https://www.shopify.ca/" target="_blank" rel="noreferrer"><img src="/img/partner-shopify.png" alt="Shopify" /></a>
                <a href="https://boldcommerce.com/" target="_blank" rel="noreferrer"><img src="/img/partner-bold.png" alt="Bold Commerce" /></a>
                <a href="https://www.zapiet.com/" target="_blank" rel="noreferrer"><img src="/img/partner-zapiet.png" alt="Zapiet" /></a>
                <a href="https://getgrocerbox.com/" target="_blank" rel="noreferrer"><img src="/img/partner-grocer-box.png" alt="Grocer Box" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="inner short">
          <h2>Service</h2>
          <div id="service">
            <div className="row equal">
              <div className='col text-center'>
                <figure style={{ backgroundImage: 'url(\'/img/partner-national.jpg\')' }}>
                  <div><i className="material-icons">public</i></div>
                </figure>
                <div><p>National retailers with a local footprint</p></div>
              </div>
              <div className='col text-center'>
                <figure style={{ backgroundImage: 'url(\'/img/partner-brick.jpg\')' }}>
                  <div><i className="material-icons">store</i></div>
                </figure>
                <div><p>Brick &amp; mortar businesses that need to deliver today</p></div>
              </div>
              <div className='col text-center'>
                <figure style={{ backgroundImage: 'url(\'/img/partner-online.jpg\')' }}>
                  <div><i className="material-icons">important_devices</i></div>
                </figure>
                <div><p>Online-first businesses that want to own delivery</p></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col text-center w75'>
            <div className="inner">
              <h2>Omni-channel, On-demand</h2>
              <p>Trexity gives you a scalable delivery fleet, fully integrated into your delivery ecosystem. Ship on-demand or according to schedule, tracked in real-time.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='nm'>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image pl">
              <img src="/img/partner-rates.jpg" alt="Phone with simplified rates" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Predictable rates, tremendous value</h2>
              <p>No commissions, monthly fees, or extra service charges. You provide white-glove level service, and all you pay for is distance and time. Your local service areas are always consistent so you can keep your promises.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal'>
          <div className='col'>
            <div className="inner">
              <h2>Unbeatable performance and capacity</h2>
              <p>Spin up your delivery fleet, schedule pick up, track each delivery in real-time, and get direct notifications at each stage of your shipment. End-to-end, made easy.</p>
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <blockquote className='quote'>
                I’m not waiting for a 6-hour pickup window, and my customers aren’t waiting hours for delivery. Everyone knows where everything is, all the time. I love it.”
              </blockquote>
            </div>
          </div>
        </div>

        <div className="steps inner short">
          <div className="entry">
            <div><i className="material-icons">drive_eta</i></div>
            <div className="line"><i className="material-icons">keyboard_arrow_right</i></div>
            <div><strong>Scalable Delivery Fleet</strong></div>
          </div>

          <div className="entry">
            <div><i className="material-icons">event_available</i></div>
            <div className="line"><i className="material-icons">keyboard_arrow_right</i></div>
            <div><strong>Scheduled Pick up</strong></div>
          </div>

          <div className="entry">
            <div><i className="material-icons">timeline</i></div>
            <div className="line"><i className="material-icons">keyboard_arrow_right</i></div>
            <div><strong>Real-time Tracking</strong></div>
          </div>

          <div className="entry">
            <div><i className="material-icons">notification_important</i></div>
            <div className="line"><i className="material-icons">keyboard_arrow_right</i></div>
            <div><strong>Direct Notifications</strong></div>
          </div>

          <div className="entry">
            <div><i className="material-icons" style={{ color: '#31D300' }}>check_circle</i></div>
            <div className="line"></div>
            <div><strong>Delivery Confirmation</strong></div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h2>A unified view</h2>
              <p>Track every package and request through the Trexity Merchant Portal. Set pick-up locations, add delivery details, and we’ll take care of the drivers.</p>
              <Link to='/merchants' className='cta-link'>Get Started <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/home-interface.jpg" alt="Store front with people" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/partner-delight.jpg" alt="Store front with people" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Delight with every delivery</h2>
              <p>Give your customers the experience they deserve with a confident, personal delivery experience. Our world-class driver community is thoroughly vetted and certified in contactless delivery, ensuring that your package is in reliable hands.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="enterprise-level">
        <div className="inner">
          <h2>A Proven Enterprise-Level Platform</h2>
          <div className="steps" style={{ margin: '40px 0' }}>
            <div className="entry">
              <div><i className="material-icons">verified_user</i></div>
              <div>Carrier-grade SLAs (delivery schedules, privacy, and security)</div>
            </div>

            <div className="entry">
              <div><i className="material-icons">settings_ethernet</i></div>
              <div>Comprehensive integration with your existing logistics, transportation, and CRM systems</div>
            </div>

            <div className="entry">
              <div><i className="material-icons">campaign</i></div>
              <div>Auto-dispatch from multiple locations, warehouses, and 3PL partners with reliable ETAs</div>
            </div>

            <div className="entry">
              <div><i className="material-icons">add_a_photo</i></div>
              <div>Multiple delivery types, IDs, and photo requirements</div>
            </div>

            <div className="entry">
              <div><i className="material-icons">settings</i></div>
              <div>Sophisticated shipping APIs make Trexity a seamless part of your logistics chain</div>
            </div>
          </div>
          <MerchantSignUpButton />
        </div>
      </section>
    </Layout>
  )
}
